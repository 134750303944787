import React, { useMemo, useState } from 'react';
import {
  notification,
  Row,
  Col,
  Card,
  Tooltip,
  Modal,
} from 'antd';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import { formatError } from 'utils';

import { Button } from 'components/gyramais';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import BUSINESS from '../../../../../../graphql/business.gql';
import SAVE_OPT_IN from './graphql/saveOptIn.gql';

import './styles.less';

import {
  termsOfUseBadesc,
  termsOfUseBadescInovation,
  termsOfUseBadescWoman,
  termsOfUseBadescGeneral,
} from './terms';

const AllTermsOfUseBadesc = {
  'Pronampe Emergencial CP': termsOfUseBadesc,
  'Pronampe Emergencial SE': termsOfUseBadesc,
  'Pronampe Inovação': termsOfUseBadescInovation,
  'Pronampe Mulher': termsOfUseBadescWoman,
  'Pronampe Geral': termsOfUseBadescGeneral,
};

const OptInStep = ({
  businessId,
  businessUserId,
  loading: parentLoading,
}) => {
  const [getIpLoading, setIpLoading] = useState(false);
  const [cacheIp, setCacheIp] = useState();

  const [badescModalOpen, setBadescModalOpen] = useState();

  const OPT_INS = {
    'TERMS-AND-POLICIES': {
      name: 'Termos e Políticas',
      terms: [
        {
          name: 'Termos e Condições do Solicitante',
          url: 'https://faq.gyramais.com.br/termos-de-uso/',
        }, {
          name: 'Políticas de Privadade',
          url: 'https://faq.gyramais.com.br/politica-de-privacidade/',
        }, {
          name: 'Termos da Registradora',
          url: 'https://faq.gyramais.com.br/termos-de-consulta-registradoras/',
        },
      ],
      types: [
        'TERMS-AND-POLICIES',
        'RECEIVABLES-REGISTRY',
      ],
    },
    BADESC: {
      name: 'BADESC',
      terms: [
        {
          name: 'Termos do Badesc',
          openModalFunc: () => setBadescModalOpen(true),
        },
      ],
      types: [
        'BADESC',
      ],
    },
    WHATSAPP: {
      name: 'WhatsApp',
      spanText: 'A pessoa pode receber atualizações sobre seu financiamento diretamente no celular.',
      types: [
        'WHATSAPP',
      ],
    },
  };

  const {
    data: {
      business,
    } = {},
    refetch,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });
  const loan = business.loans[0];

  const [businessUser, optInsByType] = useMemo(() => {
    const businessUser = business?.users?.find(({ id }) => id === businessUserId);
    if (!businessUser) {
      return [];
    }

    const optInsByType = businessUser.user.optIns.reduce((acc, optIn) => {

      if (!acc[optIn.type]) {
        acc[optIn.type] = optIn;
      }

      return acc;
    }, {});

    return [businessUser, optInsByType];
  }, [business]);

  const optIns = [{
    optIn: optInsByType?.WHATSAPP,
    value: 'WHATSAPP',
  }, {
    optIn: optInsByType?.['TERMS-AND-POLICIES'],
    value: 'TERMS-AND-POLICIES',
  }, {
    optIn: optInsByType?.BADESC,
    value: 'BADESC',
  }];

  const [saveOptIn, { loading: optInLoading }] = useMutation(SAVE_OPT_IN, {
    onCompleted: () => {
      refetch();
      notification.success({ message: 'OptIn salvo com sucesso', duration: 10 });
    },
    onError: (error) => notification.error(formatError(error)),
  });

  const handleOptIn = async (types, accepted) => {
    let ip = cacheIp;

    if (!ip) {
      setIpLoading(true);
      const response = await fetch('https://api.ipify.org/?format=json');
      const responseJSON = await response.json();
      ip = responseJSON.ip;
      setCacheIp(ip);
      setIpLoading(false);
    }

    saveOptIn({
      variables: {
        ip,
        accepted,
        types,
        signToUserId: businessUser.user.id,
      },
    });
  };

  const loading = parentLoading || !business || optInLoading || getIpLoading;
  return (
    <Row gutter={[12, 12]}>
      {optIns.map(({ value, optIn }) => {
        const optInData = OPT_INS[value];
        if (!optInData) return (<></>);
        return (
          <Col xs={24} sm={24} key={value}>
            <Card className="opt-in-card">
              <Row justify="space-between">
                <Col>
                  <span className="title">
                    {optInData.name}
                  </span>
                </Col>
                {optIn && (
                <Col>
                  <div className="opt-in-icon">
                    {optIn.accepted && (
                    <Tooltip title="OptIn aceito">
                      <CheckOutlined style={{ fontSize: 24 }} />
                    </Tooltip>
                    )}
                    {!optIn.accepted && (
                    <Tooltip title="OptIn rejeitado">
                      <CloseOutlined style={{ fontSize: 24 }} />
                    </Tooltip>
                    )}
                  </div>
                </Col>
                )}
              </Row>
              {!optIn && (
              <>
                <div className="body">
                  {optInData.terms?.map(({ name, url, openModalFunc }) => (
                    <Row>
                      <br />
                      {url && (
                      <a className="secondary-text" href={url} target="_blank" rel="noreferrer">
                        {name}
                      </a>
                      )}
                      {openModalFunc && (
                        <Button
                          color="link"
                          onClick={openModalFunc}
                        >
                          {name}
                        </Button>
                      )}
                      <br />
                    </Row>
                  ))}
                  {optInData.spanText && (
                  <Row>
                    <span>
                      {optInData.spanText}
                    </span>
                    <br />
                  </Row>
                  )}
                </div>

                <Row gutter={8} justify="end">
                  <Col>
                    <Button
                      loading={loading}
                      onClick={() => handleOptIn(optInData.types, true)}
                    >
                      Aceitar
                    </Button>
                  </Col>
                  {value === 'WHATSAPP' && (
                  <Col>
                    <Button
                      loading={loading}
                      onClick={() => handleOptIn(optInData.types, false)}
                    >
                      Rejeitar
                    </Button>
                  </Col>

                  )}
                </Row>
              </>
              )}
            </Card>
          </Col>
        );
      })}

      <Modal
        title="Termos de uso"
        visible={badescModalOpen}
        onCancel={() => {
          setBadescModalOpen(false);
        }}
        footer={(
          <Button onClick={() => setBadescModalOpen(false)}>
            Fechar
          </Button>
        )}
      >
        <div className="badesc-terms-container">
          <pre>
            {AllTermsOfUseBadesc[loan.portfolioName]}
          </pre>
        </div>
      </Modal>
    </Row>
  );
};

export default OptInStep;
